import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
import ContactForm from 'components/forms/contact';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`You can contact me by filling out the form below:`}</p>
    <ContactForm mdxType="ContactForm" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      